import React from "react";
import benzaldehyde from "../../assets/img/products/benzaldehyde.jpg";
import benzylAcetate from "../../assets/img/products/benzyl-acetate.jpg";
import benzylAlcohol from "../../assets/img/products/benzyl-alcohol.jpg";
import benzylBenzoate from "../../assets/img/products/benzyl-benzoate.jpg";
import benzylChloride from "../../assets/img/products/benzyl-chloride.jpg";
import benzylCyanide from "../../assets/img/products/benzyl-chloride.jpg";

import benzaldehydeDataSheet from "../../assets/safety-data-sheets/BENZALDEHYDE.pdf";
import benzylAcetateDataSheet from "../../assets/safety-data-sheets/BENZYL ACETATE.pdf";
import benzylAlcoholDataSheet from "../../assets/safety-data-sheets/BENZYL ALCOHOL.pdf";
import benzylBenzoateDataSheet from "../../assets/safety-data-sheets/BENZYL BENZOATE.pdf";
import benzylChlorideDataSheet from "../../assets/safety-data-sheets/BENZYL CHLORIDE.pdf";
import benzylCyanideDataSheet from "../../assets/safety-data-sheets/BENZYL CYANIDE.pdf";

// import benzylSalicylate from "../../assets/img/products/benzyl-salicylate.jpg";

var products = [
  {
    prefix: "BCHO",
    name: "BENZALDEHYDE",
    image: benzaldehyde,
    formula: "C7H6O",
    casNumber: "100-52-7",
    detailLink: benzaldehydeDataSheet,
    cssColorClass: "product-danger",
    basicInformations: [
      "Product Name : BENZALDEHYDE",
      "Other name: Benzenecarbaldehyde, Phenylmethanal, Benzoic Aldehyde, Benzenecarbonal, Benzenecarboxaldehyde, Benzoyl hydride, Phenylformaldehyde, Benzene methylal.",
      "Application : Benzaldehyde is used in manufacturing of  Cinnamaldehyde ,Alpha Amyl Cinnamaldehyde, Cinnamic Alcohol ,Rose Crystals, Meta Phenoxy Benzaldehyde ,Malachite Green ,Congo Red, Congo Scarlet",
      "Chemical Name : benzaldehyde",
      "Formula : C7H6O",
      "Molecular Weight : 106.12",
      "CAS Number : 100-52-7",
      "EINECS Number : 202-860-4",
      "FEMA Number : 2127",
      "Flash Point : 62.78 °C",
      "Boiling Point : 179 °C @ 760 mm Hg",
      "Packing & Storage: 220 Kg HDPE drum, Tanker, ISO Tank. It is stored in tightly closed container in a cool & dry ventilated area",
    ],
    specifications: [
      "Appearance : Clear Liquid",
      "Color : Colorless to pale yellow",
      "Odor : Sweet, bitter almonds",
      "Specific Gravity at 25 °C : 1.0410 - 1.0460",
      "Refractive Index at 20 °C : 1.5440 - 1.5460",
      "Acid Value (MgKOH/gm) : < 0.5",
      "Purity (By GC) : > 99.00 %",
      "Shelf Life : 6 months",
    ],
  },
  {
    prefix: "BCL",
    name: "BENZYL CHLORIDE",
    image: benzylChloride,
    formula: "C7H7C1",
    casNumber: "100-44-7",
    detailLink: benzylChlorideDataSheet,
    cssColorClass: "product-primary",
    basicInformations: [
      "Product Name : BENZYL CHLORIDE",
      "Other name: Chlorophenylmethane, Chlorotoluene, Chloromethylbenzene, Tolyl chloride, Phenylmethyl chloride",
      "Application : Benzyl Chloride is used in manufacturing of - Dyes - Drug Intermediates - Benzyl Compounds - Synthetic Tannins - Perfumery - Pharmaceuticals - Photographic Developer - Penicillin Precursors - Gasoline Gum - Inhibitors - Quaternary Ammonium Compounds",
      "Chemical Name : benzyl chloride",
      "Formula : C7H7Cl ",
      "Molecular Weight : 126.58 ",
      "CAS Number : 100-44-7 ",
      "EINECS Number : 202-853-6 ",
      "Flash Point : 73.89 °C ",
      "Boiling Point : 197 °C @ 760 mm Hg",
      "Packing & Storage:  230 Kg HDPE drum, 1050 kg IBC, Road Tanker, ISO tank. It is stored in tightly closed container in a cool & dry ventilated area.",
    ],
    specifications: [
      "Appearance : Liquid ",
      "Color : Colorless ",
      "Odor : Irritating, pungent ",
      "Specific Gravity at 25 °C : 1.092 - 1.111 ",
      "Toluene : < 0.05 %",
      "Benzylidene Chloride : < 0.25 % ",
      "Chloro Toluene : < 0.25 % ",
      "Purity (By GC) : > 99.00 % ",
      "Shelf Life : Original characteristics remain intact for 06 months, if kept in recommended storage. ",
    ],
  },
  {
    prefix: "BCY",
    name: "BENZYL CYANIDE",
    image: benzylCyanide,
    formula: "C8H7N",
    casNumber: "140-29-4",
    detailLink: benzylCyanideDataSheet,
    cssColorClass: "product-info",
    basicInformations: [
      "Product Name :Benzyl Cyanide",
      "Other name : Phenyl  acetonitril",
      "Molecular formula: C8H7N",
      "CAS No.: 140-29-4",
      "molecular weight: 117.15 g/mol",
      "boiling point : 233 to 234 °C ",
      "Specific gravity: 1.015 gm/ml at 25°C",
      "Application: Benzyl cyanide is used in the organic synthesis of dyes, fragrances, pesticides, and pharmaceuticals, such as penicillin precursors, Fexofinadine, ethoheptazine, ketobemidone, pethidine, and phenoperidine,disopyramide,pyrimethamine, triamterene.",
      "Packing & Storage : 220 Kg HDPE drum, It is stored in tightly closed container in a cool & dry ventilated area",
    ],
    specifications: [
      "ASSAY BY G.C. :  99% MIN",
      "Benzyl Chloride :  0.1% MAX",
      "Benzyl alcohol : 0.3% MAX",
      "Moisture :  0.2% MAX",
    ],
  },
  {
    prefix: "BACE",
    name: "BENZYL ACETATE",
    image: benzylAcetate,
    formula: "C9H10O2",
    casNumber: "140-11-4",
    detailLink: benzylAcetateDataSheet,
    cssColorClass: "product-warning",
    basicInformations: [
      "Product Name : BENZYL ACETATE",
      "Application : Raw material for industrial use intended for the manufacture of Flavors and/or Fragrances",
      "Chemical Name : Acetic acid benzyl ester",
      "Formula : C9H10O2",
      "Molecular Weight : 150.18",
      "CAS Number : 140-11-4",
      "EINECS Number : 205-399-7",
      "FEMA Number : 2135",
      "Flash Point : 95 °C",
      "Boiling Point : 217 °C @ 760 mm Hg",
      "Packing & Storage : 220 Kg HDPE drum, Tanker, ISO Tank. It is stored in tightly closed container in a cool & dry ventilated area",
    ],
    specifications: [
      "Appearance : Clear Mobile Liquid",
      "Color : Colorless",
      "Odor : Jasmin , fruity",
      "Specific Gravity at 25 °C : 1.0490 - 1.0590",
      "Refractive Index at 20 °C : 1.5000 - 1.5040",
      "Acid Value (MgKOH/gm) : < 1.0",
      "Purity (By GC) : > 99.50 %",
      "Shelf Life : 12 months",
    ],
  },
  {
    prefix: "BB",
    name: "BENZYL BENZOATE",
    image: benzylBenzoate,
    formula: "C14H12O2",
    casNumber: "120-51-4",
    detailLink: benzylBenzoateDataSheet,
    cssColorClass: "product-dark",
    basicInformations: [
      "Product Name : BENZYL BENZOATE ",
      "Application : Raw material for industrial use intended for the manufacture of Flavors and/or Fragrances ",
      "Chemical Name : phenyl methyl benzoate",
      "Formula : C14H12O2 ",
      "Molecular Weight : 212.25 ",
      "CAS Number : 120-51-4",
      "EINECS Number : 204-402-9 ",
      "FEMA Number : 2138 ",
      "Flash Point : 158 °C",
      "Boiling Point : 323-324 °C @ 760 mm Hg",
      "Packing & Storage : 220 Kg HDPE drum, It is stored in tightly closed container in a cool & dry ventilated area",
    ],
    specifications: [
      "Appearance : Clear Liquid ",
      "Color : Colorless to pale yellow,20 APHA (Max)",
      "Odor : Very faint, mild, balsamic",
      "Specific Gravity at 25 °C : 1.1130 - 1.1210 ",
      "Refractive Index at 20 °C : 1.5660 - 1.5710 ",
      "Acid Value (MgKOH/gm) : < 1.0 ",
      "Purity (By GC) : > 99.00 % ",
      "Shelf Life : 12 months",
      "Benzaldehyde Content 0.10% (Max) ",
      "Benzyl Alcohol 0.10% (Max) ",
      "Freezing Point 18.0°C (Min) ",
    ],
  },
  {
    prefix: "BOH",
    name: "BENZYL ALCOHOL",
    image: benzylAlcohol,
    formula: "C7H8O",
    casNumber: "100-51-6",
    detailLink: benzylAlcoholDataSheet,
    cssColorClass: "product-success",
    basicInformations: [
      "Product Name : BENZYL ALCOHOL ",
      "Application : Solvent for Inks, Paints, Lacquers & Epoxy Resin Coatings",
      "Chemical Name : phenyl methanol , Phenylcarbinol, Benzoyl alcohol, Benzenemethanol, Phenylmethyl Alcohol, Benzenecarbinol, Alpha-Hydroxytoluene.",
      "Formula : C7H8O ",
      "Molecular Weight : 108.14 ",
      "CAS Number : 100-51-6 ",
      "EINECS Number : 202-859-9 ",
      "FEMA Number : 2137 ",
      "Flash Point : 62.78 °C ",
      "Boiling Point : 179 °C @ 760 mm Hg",
      "Packing & Storage : Benzyl Alcohol is packed in 220 Kg GI drum/HMHDPE drum, IBC, ISO tank. It is stored in tightly closed container in a cool & dry ventilated area.",
    ],
    specifications: [
      "Appearance : Clear Liquid ",
      "Color : Colorless ",
      "Odor : Sweet, floral, fruity",
      "Specific Gravity at 25 °C : 1.0410 - 1.0460 ",
      "Refractive Index at 20 °C : 1.5385 - 1.5405",
      "Acid Value (MgKOH/gm) : < 1.0 ",
      "Purity (By GC) : > 99.50 % ",
      "Benzaldehyde Content : 0.10% (max)",
      "Dibenzyl Ether Content: 0.10% (max)",
      "Shelf Life : 12 months",
    ],
  },
];

export const Product = () => {
  return (
    <section id="products" className="projects">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Our Products</h2>
        </div>
        <div className="row gy-5" data-aos="fade-up" data-aos-delay="200">
          {products.map((product) => (
            <div className="col-lg-4 col-md-6" key={product.name}>
              <button
                type="button"
                className={product.cssColorClass + " product"}
                data-bs-toggle="modal"
                data-bs-target={"#" + product.name.replace(" ", "")}
              >
                <h5>{product.name}</h5>
                <li className="list-group-item">
                  CAS NO : {product.casNumber}
                </li>
                <li className="list-group-item">FORMULA : {product.formula}</li>
              </button>
            </div>
          ))}
        </div>
      </div>

      {products.map((product) => (
        <div
          key={product.name}
          className="modal fade"
          id={product.name.replace(" ", "")}
          aria-labelledby={product.name.replace(" ", "") + "Label"}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id={product.name}>
                  {product.name}
                </h5>
                <a
                  href={product.detailLink}
                  className="btn btn-outline-primary download-brochure"
                  download={product.name + ".pdf"}
                >
                  <i
                    className="bi bi-download"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Get Safety Data Sheet
                </a>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h5>Basic Information</h5>
                <ul>
                  {product.basicInformations.map((basicInformation) => (
                    <li key={basicInformation}>{basicInformation}</li>
                  ))}
                </ul>
                <h5>Specifications</h5>
                <ul>
                  {product.specifications.map((specifications) => (
                    <li key={specifications}>{specifications}</li>
                  ))}
                </ul>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};
