import React from "react";
import md from "../../assets/img/md.jpg";
import md1 from "../../assets/img/md2.jpg";
import md2 from "../../assets/img/md3.jpg";

export const Team = () => {
  return (
    <section id="team" className="team">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Our Team</h2>
          <p>
            The trio in the triangle of Kasturi Aromatics has always joined
            shoulders to guide, move and motivate one and all to execute the
            efficient plans through efficient team. All the directors are backed
            at the base of Masters in Pharmacy, at the base of work experience
            in different renowned MNC’S in various sectors. Combined and
            diligent efforts makes a perfect requirement to run an organization
            and to take it to the newer heights.
          </p>
        </div>

        <div className="row gy-5">
          <div
            className="col-lg-4 col-md-6 member"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="member-img">
              <img
                src={md}
                className="img-fluid"
                alt=""
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className="member-info text-center">
              <h4>VISHAL S. BHUT</h4>
              <span>Director</span>
              <p>
                +91 88662 28833
                <br></br>
                vishal@kasturiaromatics.com
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 member"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="member-img">
              <img src={md1} className="img-fluid" alt="" />
            </div>
            <div className="member-info text-center">
              <h4>PANKAJ D. CHORAI</h4>
              <span>Director</span>
              <p>
                +91 97221 89281
                <br></br>
                pankaj@kasturiaromatics.com
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 member"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="member-img">
              <img src={md2} className="img-fluid" alt="" />
            </div>
            <div className="member-info text-center">
              <h4>KAPIL M. MESAVANIYA</h4>
              <span>Director</span>
              <p>
                +91 74059 14222
                <br></br>
                kapil@kasturiaromatics.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
