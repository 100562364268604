import React from "react";
import logo from "../assets/img/logo.png";
import companyName from "../assets/img/company-name.png";
import logoWithCompanyName from "../assets/img/logo-with-company-name.png";

export const Header = () => {
  return (
    <header
      id="header"
      className="header d-flex align-items-center bg-light fixed"
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="#home" className="logo d-flex align-items-center">
          <img className="logo-img" src={logo} alt="" />
          <img className="logo-company-name-img" src={companyName} alt="" />
          <img
            className="logo-with-company-name-img"
            src={logoWithCompanyName}
            alt=""
          />
        </a>

        <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a className="nav-link" href="#home">
                Home
              </a>
            </li>
            <li>
              <a className="nav-link" href="#aboutUs">
                About
              </a>
            </li>
            <li>
              <a className="nav-link " href="#products">
                Product
              </a>
            </li>
            <li>
              <a className="nav-link " href="#testimonials">
                Testimonials
              </a>
            </li>
            <li>
              <a className="nav-link " href="#team">
                Team
              </a>
            </li>
            <li>
              <a className="nav-link" href="#contactUs">
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};
