import React from "react";
import factory1 from "../../assets/img/about-carousel/fac-1.jpg";
import factory2 from "../../assets/img/about-carousel/fac-2.jpg";
import factory3 from "../../assets/img/about-carousel/fac-3.jpg";
import factory4 from "../../assets/img/about-carousel/fac-4.jpg";
import factory5 from "../../assets/img/about-carousel/fac-5.jpg";

var carouselItems = [factory2, factory3, factory4, factory5];

export const AboutUs = () => {
  return (
    <section id="aboutUs" className="get-started section-bg">
      <div className="container">
        <div className="row justify-content-between gy-4">
          <div
            className="col-lg-6 d-flex align-items-center"
            data-aos="fade-up"
          >
            <div className="content">
              <h3>About Us</h3>
              <p>
                Kasturi Aromatics has its roots in 2014 indirectly, wiring its
                end with Waylon Pharmaceuticals (The Parent Organization), which
                was involved in manufacturing of Benzyl Acetate. Since then
                there is no turning back, and the present Kasturi Aromatics is
                the wider picture of its assiduous parent organization.
              </p>
              <p>
                Kasturi Aromatics is Accredited To <b>ISO 14001:2015</b> And{" "}
                <b>ISO 9001:2015.</b> All Products of Kasturi Aromatics are{" "}
                <b>Kosher Certified</b> and <b>Halal Complied</b>.
              </p>
              <br />
              <h3>Our Mission</h3>
              <center>
                <h4>
                  सर्वं परवशं दुःखं सर्वमात्मवशं सुखम्।
                  <br></br>
                  एतद् विद्यात् समासेन लक्षणं सुखदुःखयोः॥
                </h4>
              </center>
              <p>
                Everything that is in other’s control is painful. All that is in
                self-control is happiness. This is the definition of happiness
                and pain in short.
              </p>

              <p>
                We strongly believe in the PM of India’s message of guiding the
                country to become the self-resilient and are marching in same
                direction though a millimetre. We will always be in line with
                the mother nature and are obliged to practice the clean
                chemistry manufacturing. We at Kasturi Aromatics also keep
                practicing the welfare and progress for all and making a happy
                place to work at.
              </p>
            </div>
          </div>

          <div className="col-lg-5" data-aos="fade">
            <div
              id="carouselExampleControls"
              className="carousel slide carousel-fade"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="1500">
                  <img src={factory1} className="d-block w-100" alt="..." />
                </div>
                {carouselItems.map((img) => (
                  <div
                    className="carousel-item"
                    data-bs-interval="3000"
                    key={img}
                  >
                    <img src={img} className="d-block w-100" alt="..." />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
