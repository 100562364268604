import React from "react";
import img1 from "../assets/img/hero-carousel/hero-carousel-6.jpg";
import img2 from "../assets/img/hero-carousel/factory1.jpg";

export const Intro = () => {
  return (
    <section id="home" className="hero">
      <div className="info d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 text-center">
              <h2 data-aos="fade-down">
                Welcome to <br />
                <span>Kasturi Aromatics</span>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div
        id="hero-carousel"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="5000"
      >
        <div
          className="carousel-item active"
          style={{
            backgroundImage: `url(${img2})`,
          }}
        ></div>
      </div>
    </section>
  );
};
