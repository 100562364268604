import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export const Contact = () => {
  let formRef = useRef();

  let nameRef = useRef();
  let emailRef = useRef();
  let subjectRef = useRef();
  let messageRef = useRef();

  var [isMessageSent, setIsMessageSent] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    emailjs
      .sendForm("service_fxifs8q", "template_segd7cd", formRef.current, "QNapLBMaMvEYdiz6m")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setIsMessageSent(true);
    nameRef.current.value = "";
    emailRef.current.value = "";
    subjectRef.current.value = "";
    messageRef.current.value = "";
  };

  return (
    <section id="contactUs" className="contact section-bg">
      <div className="section-header">
        <h2>Contact Us</h2>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="info-item  d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-map"></i>
              <h3>Our Address</h3>
              <p>Kasturi Aromatics</p>
              <p>C/219, Sayakha, GIDC, Gujarat (India) 392140</p>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div
              className="info-item d-flex flex-column justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <i className="bi bi-envelope"></i>
              <h3>Email Us</h3>
              <p>info@kasturiaromatics.com</p>
            </div>
          </div>

          {/* <div className="col-lg-3 col-md-6">
            <div className="info-item  d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-telephone"></i>
              <h3>Call Us</h3>
              <p>+91 88662 28833</p>
            </div>
          </div> */}
        </div>

        <div className="row gy-4 mt-1">
          <div className="col-lg-6 ">
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3704.9712991718275!2d72.82123107519786!3d21.781371780063136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f8785b9f9175b%3A0xb8651af88aa01080!2sKasturi%20Aromatics%20GIDC%20Sayakha!5e0!3m2!1sen!2sin!4v1688493322864!5m2!1sen!2sin"
              width="100%"
              height="100%"
            ></iframe> */}

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d463.15742199263155!2d72.81957809687894!3d21.770220301978807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f86c684bb62fd%3A0xf72f09e80a0d87!2sKasturi%20Aromatics!5e0!3m2!1sen!2sin!4v1688626150066!5m2!1sen!2sin"
              width={"100%"}
              height={"100%"}
              loading={"lazy"}
            ></iframe>
          </div>

          <div className="col-lg-6">
            <form
              className="php-email-form"
              method="post"
              ref={formRef}
              onSubmit={handleFormSubmit}
            >
              <div className="row gy-4">
                <div className="col-lg-6 form-group">
                  <input
                    type="text"
                    name="name"
                    ref={nameRef}
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    ref={emailRef}
                    id="email"
                    placeholder="Your Email"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  ref={subjectRef}
                  id="subject"
                  placeholder="Subject"
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  ref={messageRef}
                  rows="5"
                  placeholder="Message"
                  style={{ resize: "none" }}
                  required
                ></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div
                  className="sent-message"
                  style={{ display: isMessageSent ? "block" : "none" }}
                >
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
