import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Intro } from "./components/Intro";
import { AboutUs } from "./components/body/AboutUs";
import { Contact } from "./components/body/Contact";
import { Product } from "./components/body/Product";
import { Team } from "./components/body/Team";
import { Testimonials } from "./components/body/Testimonials";

function App() {
  return (
    <>
      <Header />
      <Intro />

      <main id="main">
        <AboutUs />
        <Product />
        {/* <Testimonials /> */}
        <Team />
        <Contact />
      </main>

      <a
        href="#"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
      <Footer />
    </>
  );
}

export default App;
