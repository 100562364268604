import React from "react";

export const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-legal text-center position-relative">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Kasturi Aromatics</span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
};
